import React from "react";
import { PieChart, Pie, Tooltip, Cell, Legend } from "recharts";

const PieChartOne = ({ data, caption }) => (
  <div style={{ width: 600, height: 350 }}>
    <div
      className="custom-tooltip"
      style={{ marginTop: "0.5em", fontSize: "18px", fontWeight: "bold" }}
    >
      {caption}
    </div>
    <PieChart width={600} height={300}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={100}
        innerRadius={50}
        fill="#8884d8"
        label
        paddingLeft={50}
      >
        {data.map((entry, index) => (
          <Cell key={index} fill={entry.color} />
        ))}
      </Pie>
      <Tooltip />
      <Legend
        layout="vertical"
        align="right"
        verticalAlign="middle"
        wrapperStyle={{ paddingLeft: "2em", fontSize: "1.5em" }}
      />
    </PieChart>
  </div>
);

export default PieChartOne;
