export const exportAsCSV = async (data, filename) => {
  const titleKeys = Object.keys(data[0]);
  const refinedData = [];
  refinedData.push(titleKeys);

  data.forEach((item) => {
    refinedData.push(Object.values(item));
  });

  let csvContent = "";

  refinedData.forEach((row) => {
    csvContent += row.join(",") + "\n";
  });

  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename + "_export.csv";
  link.click();
  URL.revokeObjectURL(url);
};
