import dayjs from "dayjs";

export function formatDate(date) {
  return dayjs(date).format("YYYY-MM-DD");
}

export function formatDate2(date) {
  return dayjs(date).format("YYYY-MM-DDTHH:mm:ss");
}

export function generateDateBasedOnRange(input) {
  let from;
  let to;
  if (input === "1") {
    from = dayjs().startOf("day").add(1, "day");
    to = dayjs().add(1, "day").endOf("day");
  } else if (input === "customRange") {
    from = dayjs().startOf("day");
    to = dayjs().startOf("day");
  } else {
    from = dayjs().startOf("day");
    to = dayjs()
      .add(Number(input) - 1, "days")
      .endOf("day");
  }

  return { from, to };
}

// Disable dates longer than 30 days in DataPicker
export function shouldDisableDate(date) {
  const currentDate = dayjs();
  const maxDate = currentDate.add(30, "day");
  return date.isAfter(maxDate, "day");
}
