import { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useProSidebar } from "react-pro-sidebar";
import {
  Box,
  useTheme,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import StorageIcon from "@mui/icons-material/Storage";

import useAuth from "../hooks/useAuth";
import useSidebar from "../hooks/useSidebar";

import logoImage from "../assets/logo_simple.png";

const Topbar = () => {
  const { isAuthenticated, logout } = useAuth();
  const { isVisible, setVisible } = useState("true");
  const { openSidebar } = useSidebar();

  const { collapseSidebar, toggleSidebar, collapsed, broken, open } =
    useProSidebar();

  const theme = useTheme();
  const colors = theme.palette;
  const location = useLocation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleLogOut = () => {
    logout();
  };
  const handleAdminClick = () => {
    openSidebar();
  };

  useEffect(() => {}, [isAuthenticated, logout]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={1}
      bgcolor={colors.primary.main}
    >
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Box>
          <img src={logoImage} alt="foodrus logo" height={30} />
        </Box>
        <Box marginTop={1}>
          <Typography color="white" fontSize={15} paddingLeft={2}>
            STOCK OPTIMIZATION
          </Typography>
        </Box>
      </Box>
      {/* icons */}
      {isAuthenticated && (
        <Box display="flex">
          <Tooltip
            placement="bottom"
            title={<Typography fontSize={14}>Dashboard</Typography>}
          >
            <IconButton
              size={isSmallScreen ? "small" : "medium"}
              component={Link}
              to="/dashboard"
              style={
                location.pathname === "/dashboard"
                  ? { color: colors.secondary.main }
                  : { color: colors.lightGrey.main }
              }
            >
              <DashboardOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={<Typography fontSize={14}>Inventory</Typography>}
            placement="bottom"
            sx={{ fontSize: 30 }}
          >
            <IconButton
              size={isSmallScreen ? "small" : "medium"}
              component={Link}
              to="/inventory"
              style={
                location.pathname === "/inventory"
                  ? { color: colors.secondary.main }
                  : { color: colors.lightGrey.main }
              }
            >
              <BarChartOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={<Typography fontSize={14}>Report</Typography>}
            placement="bottom"
            sx={{ fontSize: 30 }}
          >
            <IconButton
              size={isSmallScreen ? "small" : "medium"}
              component={Link}
              to="/report"
              style={
                location.pathname === "/report"
                  ? { color: colors.secondary.main }
                  : { color: colors.lightGrey.main }
              }
            >
              <StorageIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={<Typography fontSize={14}>History</Typography>}
            placement="bottom"
          >
            <IconButton
              size={isSmallScreen ? "small" : "medium"}
              component={Link}
              to="/history"
              style={
                location.pathname === "/history"
                  ? { color: colors.secondary.main }
                  : { color: colors.lightGrey.main }
              }
            >
              <HistoryOutlinedIcon />
            </IconButton>
          </Tooltip>
          {isVisible && (
            <Tooltip
              title={<Typography fontSize={14}>Admin</Typography>}
              placement="bottom"
            >
              <IconButton onClick={handleAdminClick}>
                <AdminPanelSettingsOutlinedIcon
                  style={{ color: colors.lightGrey.main }}
                />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip
            title={<Typography fontSize={14}>Logout</Typography>}
            placement="bottom"
          >
            <IconButton
              size={isSmallScreen ? "small" : "medium"}
              onClick={handleLogOut}
              style={{ color: colors.lightGrey.main }}
            >
              <ExitToAppOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default Topbar;
