import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export const useErrorHandler = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (error) => {
    if (error.message && error.message === "Token has expired") {
      logout();
      navigate("/login");
    } else if (error.message) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage("An error occurred. Please try again.");
    }
  };
  return { handleError, errorMessage };
};
