import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DownloadIcon from "@mui/icons-material/Download";
import CustomizedTables from "../components/CustomizedTables";
import Spinner from "../components/Spinner";
import AlertMessage from "../components/AlertMessage";

import { generateDateBasedOnRange } from "../utils/dateUtils";
import { CardDetail } from "../components/cards/CardDetail";
import PieChartOne from "../components/charts/PieChartOne";

import DateSelection from "../components/filter/DateSelection";
import Dropdown from "../components/filter/Dropdown";
import Header from "../components/Header";

import { exportAsCSV } from "../utils/ExportUtils";

import { useErrorHandler, errorMessage } from "../hooks/useErrorHandler";

import {
  getProducts,
  getForecastByDateRange,
  getExpiringInventory,
  getCurrentInventory,
  getProductList,
  getOverallInventory,
  getExpiredInventory,
  getExpiredInventoryAll,
} from "../api/products";
const columnsPredictionData = [
  { id: 1, headerName: "Product ID", name: "product_id" },
  {
    id: 2,
    headerName: "Predicted Quantity",
    name: "predicted_quantity",
  },
  { id: 3, headerName: "Prediction Date", name: "prediction_date" },
  { id: 4, headerName: "Minimum Expiry days", name: "minimum_expiry_days" },
  {
    id: 5,
    headerName: "Waste reduction date",
    name: "waste_reduction_date",
  },
  {
    id: 6,
    headerName: "Waste reduction (%)",
    name: "waste_reduction_percentage",
  },
];

// const columnsExpiring = [
//   { id: 1, headerName: "Product Name", name: "product_name" },
//   { id: 2, headerName: "Product ID", name: "product_id" },
//   { id: 3, headerName: "Supplier ID", name: "supplier_id" },
//   { id: 4, headerName: "Units to Expire", name: "units_to_expire" },
//   { id: 5, headerName: "Expiration Date", name: "expiration_date" },
// ];
const expiredColumns = [
  { id: 1, headerName: "Product Name", name: "product_item_name" },
  { id: 2, headerName: "Product ID", name: "product_id" },
  { id: 4, headerName: "Expired Units", name: "waste_quantity" },
  { id: 5, headerName: "Expiration Date", name: "waste_date" },
  { id: 3, headerName: "Supplier", name: "supplier" },
  { id: 3, headerName: "Category", name: "product_group" },
];
function Dashboard() {
  const { errorMessage, handleError } = useErrorHandler();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState("1"); //next day
  const [selectedOverviewDateRange, setSelectedOverviewDateRange] =
    useState("7"); //next 7 days
  const [predictionData, setPredictionData] = useState([]);
  const [productExpiring, setProductExpiring] = useState([]);
  const [expiredData, setExpiredData] = useState([]);

  const [productList, setProductList] = useState([]);
  const [selectedProductId, setSelectedProducId] = useState("");

  const [averageInventory, setAverageInventory] = useState(0);
  const [turnoverRatio, setTurnoverRatio] = useState(0);
  const [soldUnits, setSoldUnits] = useState(0);
  const [wasteAmount, setWasteAmount] = useState(0);
  const [unitsInInventory, setUnitsInInventory] = useState(0);
  const [wastePercentage, setWastePercentage] = useState(0);
  const [pieData, setPieData] = useState([
    { name: "Products", value: 0, color: "#243746" },
    { name: "Quantity of Products In Stock", value: 0, color: "#11EA42" },
    { name: "Low Stock Products (<10)", value: 0, color: "#EAB711" },
    { name: "Out of Stock Products", value: 0, color: "#ff0000" },
    // { name: "Waste", value: 0, color: "#ff0000" },
  ]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  // useEffect(() => {
  //   if (wasteAmount === 0) {
  //     setWastePercentage(0); // To avoid division by zero
  //   }
  //   var sum = 0;

  //   for (let i = 0; i < pieData.length; i++) {
  //     sum += pieData[i].value;
  //   }

  //   setWastePercentage((wasteAmount / sum) * 100);
  // }, [pieData, wasteAmount]);

  const { error: errorForecast, isLoading: isLoadingForecast } = useQuery(
    ["predictionData", startDate, endDate, selectedProductId],
    () => getForecastByDateRange(startDate, endDate, selectedProductId),
    {
      onSuccess: (data) => {
        setPredictionData(data);
      },
      onError: (error) => {
        handleError(error);
      },
      enabled: !!selectedProductId,
    }
  );

  // const { error: errorExpiring, isLoading: isLoadingExpiring } = useQuery(
  //   ["productExpiryData"],
  //   () => getExpiringInventory(),
  //   {
  //     onSuccess: (data) => {
  //       setProductExpiring(data);
  //     },
  //   }
  // );

  const { error: errorProductList, isLoading: isLoadingProductList } = useQuery(
    ["productList"],
    getProductList,
    {
      onSuccess: (data) => {
        setProductList(data);
        setSelectedProducId(data[0]?.id);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const { error: errorStockInventory, isLoading: isLoadingStockInventory } =
    useQuery(["currentInventory"], getCurrentInventory, {
      onSuccess: (data) => {
        const newPieData = pieData;
        newPieData[0].value = data?.totalProducts;
        newPieData[1].value = data?.inStockProducts;
        newPieData[2].value = data?.lowStockProducts;
        newPieData[3].value = data?.outOfStockProducts;
        // newPieData[3].value = data?.WasteProducts;
        setPieData(newPieData);
      },
      onError: (error) => {
        handleError(error);
      },
    });

  // const { error: errorTurnover, isLoading: isLoadingTurnOver } = useQuery(
  //   ["turnoverData"],
  //   getInventoryTurnover,
  //   {
  //     onSuccess: (data) => {
  //       const sum = data.reduce((acc, obj) => acc + obj["turnover"], 0);
  //       const average = sum / data.length;
  //       setTurnoverRatio(average);
  //     },
  //   }
  // );

  const { error: errorTurnover, isLoading: isLoadingTurnOver } = useQuery(
    ["overallInventory"],
    getOverallInventory,
    {
      onSuccess: (data) => {
        setTurnoverRatio(data.inventoryRatio);
        setAverageInventory(data.averageInventory);
        setSoldUnits(data.totalUnitsSold);
        setWasteAmount(data.Waste);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const { errorExpired, isLoadingExpired } = useQuery(
    ["expiredData"],
    getExpiredInventoryAll,
    {
      onSuccess: (data) => {
        setExpiredData(data);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  // const { error: errorProducts, isLoading: isLoadingProducts } = useQuery(
  //   ["productData"],
  //   getProducts,
  //   {
  //     onSuccess: (data) => {
  //       const sum = data.reduce((acc, obj) => acc + obj["order_amount"], 0);
  //       setSoldUnits(sum);
  //     },
  //   }
  // );

  // const { error: errorWaste, isLoading: isLoadingWaste } = useQuery(
  //   ["wasteData"],
  //   getExpiredInventory,
  //   {
  //     onSuccess: (data) => {
  //       const amount = data.length;

  //       const newPieData = pieData;
  //       // pieData[4].value = amount;
  //       // setPieData(newPieData);
  //       // setWasteAmount(amount);
  //     },
  //   }
  // );

  //console.log(pieData);
  const handleOverviewDateRange = (value) => {
    setSelectedOverviewDateRange(value);
  };

  const handleDateRange = (selectedDateRange) => {
    setSelectedDateRange(selectedDateRange);
  };

  const handleChangeProduct = (selectedProductId) => {
    setSelectedProducId(selectedProductId);
  };
  const handleStartDate = (startDate) => {
    setStartDate(startDate);
  };
  const handleEndDate = (endDate) => {
    setEndDate(endDate);
  };
  const generateDateRange = (dateRange) => {
    const { from, to } = generateDateBasedOnRange(dateRange);
    setStartDate(from);
    setEndDate(to);
  };

  useEffect(() => {
    generateDateRange(selectedDateRange);
  }, [selectedDateRange]);

  if (
    isLoadingProductList //||
    //   isLoadingForecast ||
    //isLoadingExpiring ||
    //   isLoadingStockInventory
  ) {
    return <Spinner />;
  }

  // if (
  //   errorMessage
  // ) {
  //   return <AlertMessage message={errorMessage} />;
  // }

  return (
    <Box m="20px">
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Header title="DASHBOARD" />
          {errorMessage && <AlertMessage message={errorMessage} />}
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <Dropdown
            selectedCategory={selectedOverviewDateRange}
            onSelect={handleOverviewDateRange}
            data={[
              { id: 1, name: "1", label: "Last day" },
              { id: 2, name: "7", label: "Last 7 days" },
              { id: 3, name: "30", label: "Last 30 days" },
              { id: 4, name: "customRange", label: "Custum Range" },
            ]}
            title="Range"
            isDisabled={true}
          />
        </Grid>
      </Grid>
      {/* Second row - filter */}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <CardDetail
            data={{
              value: turnoverRatio.toFixed(2),
              symbol: "",
              title: "Inventory Turnover ratio",
              backColor: "#5EC77B",
              isSymbol: false,
              valuePaddingTop: "2em",
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <CardDetail
            data={{
              value: averageInventory.toFixed(2),
              symbol: "",
              title: "Average Inventory",
              backColor: "#5EC77B",
              isSymbol: false,
              valuePaddingTop: "2em",
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <CardDetail
            data={{
              value: soldUnits,
              symbol: "",
              title: "Number of Units Sold",
              backColor: "#5EC77B",
              isSymbol: false,
              valuePaddingTop: "2em",
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <CardDetail
            data={{
              value: wastePercentage.toFixed(2) + "%",
              symbol: "",
              title: "Waste Percentage", // Updated title to have more text
              backColor: "#5EC77B", //"#5EC77B"
              isSymbol: false,
              valuePaddingTop: "2em",
            }}
          />
        </Grid>
        {/* Date Picker FORM*/}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          {/* <Paper
            elevation={3}
            style={{ padding: "20px", border: "1px solid #ddd" }}> */}
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            justifyContent="left"
          >
            <Grid item xs={6} md={3} lg={3}>
              <Dropdown
                selectedCategory={selectedProductId}
                onSelect={handleChangeProduct}
                data={productList}
                title="Product"
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <Dropdown
                selectedCategory={selectedDateRange}
                onSelect={handleDateRange}
                data={[
                  { id: 1, name: "1", label: "Next day" },
                  { id: 2, name: "7", label: "Next 7 days" },
                  { id: 3, name: "30", label: "Next 30 days" },
                  { id: 4, name: "customRange", label: "Custum Range" },
                ]}
                title="Range"
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <DateSelection
                onChangeDate={handleStartDate}
                dateSelected={startDate}
                selectedDateRange={selectedDateRange}
                lastNextDay={"Next"}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <DateSelection
                onChangeDate={handleEndDate}
                dateSelected={endDate}
                selectedDateRange={selectedDateRange}
                lastNextDay={"Next"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          sx={{ textAlign: "right" }}
          // mt={2}
        >
          <Button
            onClick={() => {
              exportAsCSV(predictionData, "product_milk_cream");
            }}
            disabled={predictionData.length === 0}
            variant="contained"
            color="success"
            startIcon={<DownloadIcon />}
          >
            DOWNLOAD REPORT
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomizedTables
            data={predictionData}
            tableName={
              "Predicted Fresh Milk and Cream Products Orders for Next Days"
            }
            fieldSelect=""
            alingitem="left"
            columnNames={columnsPredictionData}
            withSearch={false}
            withFilter={false}
            select={{
              name: "",
              id: "rangeSelect",
              styles: { minWidth: "150px", width: "181px", height: "36.7px" },
              selectedValue: "All",
              handleChangeValue: "handleSelectChange",
              values: [
                { id: 1, name: "All" },
                { id: 2, name: "Next 7 days" },
                { id: 3, name: "Next 15 days" },
                { id: 4, name: "Next 30 days" },
              ],
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ overflowX: "auto" }}>
          <div style={{ width: "100%", maxWidth: "800px" }}>
            <PieChartOne caption="Current Inventory" data={pieData} />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomizedTables
            data={expiredData}
            tableName={"Expired Inventory"}
            columnNames={expiredColumns}
            withSearch={false}
            withFilter={false}
            lastNextDay={"Next"}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
