import { Card, CardContent, Stack, Typography } from "@mui/material";

export const CardDetail = (props) => {
  const { value, sx, title, symbol, backColor, isSymbol, valuePaddingTop } =
    props.data;

  return (
    <Card sx={sx} style={{ height: "100%" }}>
      <CardContent style={{ display: "flex", flexDirection: "column" }}>
        <Stack backgroundColor={backColor} style={{ flex: 1 }}>
          <Typography
            color="#fff"
            align="center"
            variant="h5"
            padding="5px"
            minHeight={"50px"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {title}
          </Typography>
        </Stack>
        <Stack
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center", // Edited: Centered the value text
            paddingTop: valuePaddingTop, // Edited: Moved paddingTop to style
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            align="center" // Edited: Centered the value text
            style={{ flex: 1, display: "flex", alignItems: "center" }}
          >
            {value}
          </Typography>
        </Stack>
        {isSymbol && (
          <Stack style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h6" padding="1em" fontWeight="bold">
              {symbol}
            </Typography>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};
