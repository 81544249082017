export const PORT = process.env.PORT || "3005";
export const APP_ENDPOINT =
  process.env.REACT_APP_APP_ENDPOINT || "https://stock-optimization.apps.foodrus.eu";
export const APP_ENDPOINT_PROD =
  process.env.REACT_APP_APP_ENDPOINT_PROD || "https://solution-tools.foodrus.eu/";
export const ACCESS_TOKEN_NAME =
  process.env.REACT_APP_ACCESS_TOKEN_NAME || "access_token";
export const ALLOWED_ROLES =
  process.env.REACT_APP_ALLOWED_ROLES?.split(",") || [];

export const ROLES = { Admin: "admin", User: "user" };
