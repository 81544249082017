import axios from "axios";

import { APP_ENDPOINT } from "../utils/Constant";

//TO DO: BaseUrl should be set here and update all APIs to not contain baseURL in request
/*
const baseUrl = APP_ENDPOINT;
const api = axios.create({
  baseURL: baseUrl
});
*/

//axios instance
const api = axios.create();

// add a request interceptor to set the 'authorization' header with the token
api.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("accessTokenS51"));

    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
