import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Menu,
  Sidebar,
  MenuItem,
  useProSidebar,
  menuClasses,
} from "react-pro-sidebar";

import { useTheme, Box, Typography, IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";

const Item = ({ title, to, icon, selected, setSelected }) => {
  // const theme = useTheme();
  // const colors = theme.palette;

  return (
    <MenuItem
      rootStyles={{
        ["." + menuClasses.button]: {
          "&:hover": {
            backgroundColor: "transparent !important",
          },
        },
      }}
      active={selected === title}
      style={{ color: "white", fontWeight: "bold !important" }}
      onClick={() => setSelected(title)}
      icon={icon}
      component={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const CollapsibleProSidebar = (props) => {
  const theme = useTheme();
  const colors = theme.palette;
  const [selected, setSelected] = useState("Dashboard");
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();

  return (
    <Box>
      <Sidebar
        style={{ height: "100vh" }}
        // backgroundColor={colors.primary.main}
      >
        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  color: active
                    ? `${colors.secondary.main} !important`
                    : `${colors.primary.main} !important`,
                };
            },
          }}
        >
          <MenuItem
            rootStyles={{
              ["." + menuClasses.button]: {
                "&:hover": {
                  backgroundColor: "transparent !important",
                },
              },
            }}
            icon={
              collapsed && (
                <MenuOutlinedIcon onClick={() => collapseSidebar()} />
              )
            }
            style={{
              margin: "10px 0 20px 0",
              color: colors.lightGrey.main,
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                {/* <Typography variant="h6" color={colors.secondary.main}>
                  FoodRUs
                </Typography> */}
                <img
                  src={
                    require("../../assets/Logo_Foodrus_web-white.svg").default
                  }
                  alt="foodrus logo"
                  width={120}
                />
                <IconButton
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {/* <Box> */}
          <Item
            title="Dashboard"
            to="/dashboard"
            icon={<HomeOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />

          <Typography
            variant="h6"
            color={colors.grey[300]}
            sx={{ m: "15px 20px 5px 20px" }}
          >
            Data
          </Typography>

          <Item
            title="Statistics"
            to="/statistics"
            icon={<BarChartOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Invoices Balances"
            to="/invoices"
            icon={<ReceiptOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />

          <Typography
            variant="h6"
            color={colors.grey[300]}
            sx={{ m: "15px 20px 5px 20px" }}
          >
            History
          </Typography>
          <Item
            title="History"
            to="/history"
            icon={<HistoryOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />

          {/* </Box> */}
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default CollapsibleProSidebar;
