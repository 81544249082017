import { createTheme } from "@mui/material/styles";

const colors = {
  green: {
    100: "#ccffe5",
    200: "#99ffca",
    300: "#66ffb0",
    400: "#33ff95",
    500: "#00ff7b",
    600: "#00cc62",
    700: "#00994a",
    800: "#006631",
    900: "#003319",
  },
  black: {
    100: "#d3d7da",
    200: "#a7afb5",
    300: "#7c8790",
    400: "#505f6b",
    500: "#243746",
    600: "#1d2c38",
    700: "#16212a",
    800: "#0e161c",
    900: "#070b0e",
  },
  red: {
    100: "#ffdcde",
    200: "#ffbabd",
    300: "#ff979d",
    400: "#ff757c",
    500: "#ff525b",
    600: "#cc4249",
    700: "#993137",
    800: "#662124",
    900: "#331012",
  },
  white: {
    100: "#fcfcfc",
    200: "#f9f9f9",
    300: "#f7f7f7",
    400: "#f4f4f4",
    500: "#f1f1f1",
    600: "#c1c1c1",
    700: "#919191",
    800: "#606060",
    900: "#303030",
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#243746",
    },
    secondary: {
      main: colors.green[500],
    },
    third: {
      main: colors.red[500],
    },
    lightGrey: {
      main: colors.black[100],
    },

    text: {
      primary: colors.black[900],
      secondary: colors.black[600],
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    fontSize: 12,
    h1: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 40,
    },
    h2: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 32,
    },
    h3: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 24,
    },
    h4: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 20,
    },
    h5: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 16,
    },
    h6: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 14,
    },
  },
});

export default theme;
