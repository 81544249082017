import React from "react";

import { Box, Typography } from "@mui/material";

const Header = ({ title }) => {
  return (
    <Box>
      <Typography
        variant="h4"
        // color={colors.grey[100]}
        fontWeight="bold"
        sx={{ mb: "5px" }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default Header;
