import React from "react";

import { Box, useMediaQuery, useTheme } from "@mui/material";
import Header from "../components/Header";

const History = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Box m="20px">
      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title="HISTORY" />
      </Box>
    </Box>
  );
};

export default History;
