import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material/";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import PrivateRoute from "./components/auth/PrivateRoute";
import Topbar from "./components/Topbar";
import AdminPage from "./pages/AdminPage";
import History from "./pages/History";
import Inventory from "./pages/Inventory";
import Report from "./pages/Report";

import AuthProvider from "./context/AuthContext";
import { CollapsibleProSidebarProvider } from "./context/SidebarContext";

import theme from "./theme";
const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <CssBaseline />
          <Router>
            <CollapsibleProSidebarProvider>
              {/* <div className="app"> */}
              <div className="content">
                <main>
                  <Topbar />
                  <Routes>
                    <Route
                      path="/dashboard"
                      element={
                        <PrivateRoute>
                          <Dashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/inventory"
                      element={
                        <PrivateRoute>
                          <Inventory />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/report"
                      element={
                        <PrivateRoute>
                          <Report />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/history"
                      element={
                        <PrivateRoute>
                          <History />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/admin"
                      element={
                        <PrivateRoute>
                          <AdminPage />
                        </PrivateRoute>
                      }
                    />
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </main>
              </div>
            </CollapsibleProSidebarProvider>
          </Router>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
