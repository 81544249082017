import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { shouldDisableDate } from "../../utils/dateUtils";

const DateSelection = ({
  dateSelected,
  onChangeDate,
  lastNextDay,
  selectedDateRange,
}) => {
  const handleDate = (date) => {
    onChangeDate(date);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{ width: "100%" }}
        justifyContent="right"
        disablePast={lastNextDay === "Next"}
        disableFuture={lastNextDay === "Last"}
        shouldDisableDate={shouldDisableDate}
        format="DD/MM/YYYY"
        label="From"
        value={dateSelected || ""}
        onChange={handleDate}
        disabled={selectedDateRange !== "customRange"}
      />
    </LocalizationProvider>
  );
};

export default DateSelection;
