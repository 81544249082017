import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  tableCellClasses,
  TextField,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import { SelectSimple } from "./SelectSimple";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.primary.light,
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
    fontSize: 16,
    maxWidth: 100,
    minWidth: 50,
    // wordWrap: "break-word",
    whiteSpace: "nowrap",
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    maxWidth: 100,
    minWidth: 50,
    // wordWrap: "break-word",
    whiteSpace: "nowrap",
    border: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CaptionTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#243746", //theme.palette.primary.gra,
    color: theme.palette.common.white,
    fontSize: 18,
    maxWidth: 30,
    minWidth: 20,
    wordWrap: "break-word",
    border: 0,
  },
}));

const AdditionalTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ccc",
    color: theme.palette.common.white,
    fontSize: 25,
    padding: 0,
    border: 0,
    maxWidth: 10,
    minWidth: 10,
    wordWrap: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    padding: 0,
    border: 0,
    maxWidth: 10,
    minWidth: 10,
    wordWrap: "break-word",
  },
}));

export default function CustomizedTables({
  data,
  tableName,
  columnNames,
  withSearch,
  withFilter,
  select,
  table_id,
  alingitem,
  fieldSelect,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [selectedRange, setSelectedRange] = useState("All");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };
  const filtering = useCallback(
    (query, selected) => {
      return data.filter((row) => {
        if (selected === "all" && !query) {
          return true; // Show all rows if no range or search term is selected
        }

        const currentDate = new Date();
        const rowDate = new Date(row.date); // Assuming each row has a 'date' property

        // Calculate the date range based on the selected option
        const dateRange = {
          lastNext7days: 7,
          lastNext15days: 15,
          lastNext30days: 30,
        };

        // Compare the row date with the date range
        const diffInDays = Math.ceil(
          (currentDate - rowDate) / (1000 * 60 * 60 * 24)
        );
        const isInRange =
          selected === "all" || diffInDays <= dateRange[selected];

        // Check if the search term matches any column values
        const isMatch = Object.values(row).some((value) =>
          value.toString().toLowerCase().includes(query.toLowerCase())
        );

        if (!isMatch && query) {
          return false;
        }
        if (!isInRange) {
          return false;
        }
        return true;
      });
    },
    [data]
  );

  useEffect(() => {
    let query = searchQuery;
    let selected = selectedRange;
    if (withFilter) setFilteredData(filtering(query, selected));
    else setFilteredData(data);
  }, [selectedRange, searchQuery, withFilter, setFilteredData, filtering]);

  const tableData = filteredData ? filteredData : data;
  const rows = tableData?.slice(startIndex, endIndex);
  const columns = columnNames;

  const handleDelete = (id) => {
    console.log(`Deleting item with id: ${id}`);
  };

  const handleEdit = (id) => {
    console.log(`Editing item with ID: ${id}`);
  };

  const handleSelectChange = (value) => {
    setSelectedRange(value);
  };

  return (
    <TableContainer component={Paper} id={table_id} sx={{ height: "100%" }}>
      <Table sx={{ whiteSpace: "nowrap" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <CaptionTableCell colSpan={12} align="center">
              {tableName}
            </CaptionTableCell>
          </TableRow>
          <TableRow>
            {withSearch && (
              <TableCell colSpan={columns.length - 2} align="left">
                <TextField
                  label="Search"
                  value={searchQuery}
                  onChange={handleSearch}
                  variant="outlined"
                  size="small"
                  align="left"
                />
              </TableCell>
            )}
            {withFilter && (
              <TableCell colSpan={columns.length} align={alingitem}>
                <SelectSimple
                  name={select.name}
                  id={select.id}
                  styles={select.styles}
                  selectedValue={select.selectedValue}
                  handleChangeValue={handleSelectChange}
                  values={select.values}
                />
                {/* <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"last15days"}>Last 15 days</MenuItem>
                <MenuItem value={"last30days"}>Last 30 days</MenuItem>
              </Select> */}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            {columns.map((column, index) => (
              <StyledTableCell
                align="right"
                key={index}
                style={{
                  fontSize: 16,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {column.headerName}
              </StyledTableCell>
            ))}
            <AdditionalTableCell align="right">&nbsp;</AdditionalTableCell>
            <AdditionalTableCell align="right">&nbsp;</AdditionalTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.length > 0 ? (
            rows.map((row, index) => (
              <StyledTableRow key={index}>
                {columns.map((column, index) => (
                  <TableCell align="right" key={index} style={{ fontSize: 16 }}>
                    {row[column.name]}
                  </TableCell>
                ))}
                <AdditionalTableCell align="right">
                  <IconButton
                    onClick={() => handleEdit(row.id)}
                    aria-label="Edit"
                    style={{ padding: 0 }}
                  >
                    <EditIcon />
                  </IconButton>
                </AdditionalTableCell>
                <AdditionalTableCell align="right">
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    aria-label="Delete"
                    style={{ padding: 0 }}
                  >
                    <DeleteIcon style={{ color: "red", padding: 0 }} />
                  </IconButton>
                </AdditionalTableCell>
              </StyledTableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={12} align="center">
                No data found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[2, 5, 10, 50]}
        component="div"
        count={tableData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
