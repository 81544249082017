import axios from "./axiosConfig";
import dayjs from "dayjs";

import { APP_ENDPOINT, APP_ENDPOINT_PROD } from "../utils/Constant";
import { formatDate } from "../utils/dateUtils";

export const getProducts = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT}/products`);
    return res.data;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const getProductList = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT_PROD}/s5.1/products`);
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }

    throw new Error("Failed to fetch data");
  }
};

export const getForecastByDateRange = async (
  startDate,
  endDate,
  selectedProductId
) => {
  try {
    const res = await axios.get(
      `${APP_ENDPOINT_PROD}/s5.1/forecastData?date_gte=${formatDate(
        startDate
      )}&&date_lte=${formatDate(endDate)}&&productId=${selectedProductId}`
    );
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Failed to fetch data");
  }
};

export const getCurrentInventory = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT_PROD}/s5.1/currentInventory`);
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Failed to fetch data");
  }
};

export const getStockInventorySuppliers = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT}/stockInventorySuppliers`);
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Failed to fetch data");
  }
};

export const getExpiringInventory = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT}/expiringInventory`);
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Failed to fetch data");
  }
};

export const getExpiredInventory = async (page) => {
  try {
    const res = await axios.get(
      `${APP_ENDPOINT_PROD}/s5.1/expiredProducts?page=${page}`
    );
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Failed to fetch data");
  }
};

export const getExpiredInventoryAll = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT_PROD}/s5.1/expiredProductsAll`);
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Failed to fetch data");
  }
};

export const getOverallInventory = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT_PROD}/s5.1/overallInventory`);
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Failed to fetch data");
  }
};

export const getInventoryTurnover = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT}/inventoryTurnover`);
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Failed to fetch data");
  }
};

export const getStockInventory = async (supplier_name) => {
  try {
    const res = await axios.get(`${APP_ENDPOINT_PROD}/s5.1/productInStock`);
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Failed to fetch data");
  }
};

export const getStockInventoryTurnoverBySupplierNameAndDate = async (
  supplier_id,
  start_date,
  end_date
) => {
  try {
    const res = await axios.get(
      `${APP_ENDPOINT}/inventoryTurnover?supplier_id=${supplier_id}&expiration_date_gte=${dayjs(
        start_date
      ).format("YYYY/MM/DD")}&&expiration_date_lte=${dayjs(end_date).format(
        "YYYY/MM/DD"
      )}`
    );
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Failed to fetch data");
  }
};

export const getStockInventoryTurnoverBySupplierName = async (
  supplier_name
) => {
  try {
    const res = await axios.get(
      `${APP_ENDPOINT}/inventoryTurnover?supplier_name=${supplier_name}`
    );
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Failed to fetch data");
  }
};

export const getStockInventoryTurnover = async (supplier_name) => {
  try {
    const res = await axios.get(
      `${APP_ENDPOINT_PROD}/s5.1/inventoryTurnoverRatio`
    );
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Failed to fetch data");
  }
};
