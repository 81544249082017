import React, { createContext, useState } from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import useAuth from "../hooks/useAuth";
import CollapsibleProSidebar from "../components/sidebar/CollapsibleProSidebar";

export const SidebarContext = createContext({});

export const CollapsibleProSidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { isAuthenticated } = useAuth();

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  return (
    <ProSidebarProvider>
      <SidebarContext.Provider
        value={{ isSidebarOpen, setIsSidebarOpen, openSidebar }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}>
          {isAuthenticated && isSidebarOpen && <CollapsibleProSidebar />}

          {children}
        </div>
      </SidebarContext.Provider>
    </ProSidebarProvider>
  );
};
