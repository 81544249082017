import React, { useState } from "react";
import dayjs from "dayjs";

import Dropdown from "../components/filter/Dropdown";
import DateSelection from "../components/filter/DateSelection";
import CustomizedTables from "../components/CustomizedTables";
import DownloadIcon from "@mui/icons-material/Download";

import { Box, Button, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "@mui/material";

import AlertMessage from "../components/AlertMessage";
import Spinner from "../components/Spinner";
import Header from "../components/Header";
import {
  getExpiredInventory,
  getExpiringInventory,
  getStockInventorySuppliers,
  getStockInventoryTurnoverBySupplierName,
  getStockInventoryTurnover,
} from "../api/products";

import { exportAsCSV } from "../utils/ExportUtils";
import { useErrorHandler, errorMessage } from "../hooks/useErrorHandler";
import CustomizedTablesAPI from "../components/CustomizedTablesAPI";

const Report = () => {
  const { errorMessage, handleError } = useErrorHandler();
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [turnoverData, setTurnoverData] = useState([]);
  const [expiringData, setExpiringData] = useState([]);
  const [expiredData, setExpiredData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [totalPages, setTotalPages] = useState();

  const theme = useTheme();

  const turnoverColumns = [
    { id: 1, headerName: "Product Name", name: "item_name" },
    { id: 2, headerName: "Product ID", name: "id" },
    { id: 4, headerName: "Turnover Ratio", name: "turnoverRatio" },
    { id: 6, headerName: "Supplier Name", name: "supplier" },
    { id: 3, headerName: "Supplier ID", name: "supplier_id" },

    // { id: 5, headerName: "Expiration Date", name: "expiration_date" },
  ];

  const expiringColumns = [
    { id: 1, headerName: "Product Name", name: "product_name" },
    { id: 2, headerName: "Product ID", name: "product_id" },
    { id: 3, headerName: "Supplier ID", name: "supplier_id" },
    { id: 4, headerName: "Units to Expire", name: "units_to_expire" },
    { id: 5, headerName: "Expiration Date", name: "expiration_date" },
  ];

  const expiredColumns = [
    { id: 1, headerName: "Product Name", name: "product_item_name" },
    { id: 2, headerName: "Product ID", name: "product_id" },
    { id: 4, headerName: "Expired Units", name: "waste_quantity" },
    { id: 5, headerName: "Expiration Date", name: "waste_date" },
    { id: 3, headerName: "Supplier", name: "supplier" },
    { id: 3, headerName: "Category", name: "product_group" },
  ];

  // const { isLoading: isLoadingTurnover, isError: isErrorTurnover } = useQuery(
  //   ["turnover", selectedSupplier, startDate, endDate],
  //   () =>
  //     getStockInventoryTurnoverBySupplierNameAndDate(
  //       selectedSupplier,
  //       startDate,
  //       endDate
  //     ),
  //   {
  //     onSuccess: (data) => {
  //       setTurnoverData(data);
  //     },
  //   },
  //   {
  //     enabled:
  //       selectedSupplier !== null || startDate !== null || endDate !== null,
  //   }
  // );
  const { isLoading: isLoadingTurnover, isError: isErrorTurnover } = useQuery(
    ["turnover", selectedSupplier, startDate, endDate],
    () => getStockInventoryTurnover(),
    {
      onSuccess: (data) => {
        setTurnoverData(data);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const handleChangeSupplier = (event) => {
    setSelectedSupplier(event);
  };

  // const { errorSuppliers, isLoadingSuppliers } = useQuery(
  //   ["supplierList"],
  //   getStockInventorySuppliers,
  //   {
  //     onSuccess: (data) => {
  //       setSuppliers(data);
  //       setSelectedSupplier(data[0]["id"]);
  //       inititateStartingData(data[0]["name"]);
  //     },
  //   }
  // );

  // const { errorExpiring, isLoadingExpiring } = useQuery(
  //   ["expiringData"],
  //   getExpiringInventory,
  //   {
  //     onSuccess: (data) => {
  //       setExpiringData(data);
  //     },
  //   }
  // );

  const { errorExpired, isLoadingExpired } = useQuery(
    ["expiredData"],
    () => getExpiredInventory(1),
    {
      onSuccess: (data) => {
        setTotalPages(data.totalPages);
        setExpiredData(data.wasteData);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  // const inititateStartingData = (supplier_name) => {
  //   getStockInventoryTurnoverBySupplierName(supplier_name).then((items) => {
  //     setTurnoverData(items);
  //     setStartDate(dayjs(items[0]["expiration_date"]));
  //     setEndDate(dayjs(items[items.length - 1]["expiration_date"]));
  //   });
  // };

  const downloadAllReports = async () => {
    exportAsCSV(turnoverData, "turnover");
    // exportAsCSV(expiringData, "expiring");
    exportAsCSV(expiredData, "expired");
  };

  if (
    //isLoadingSuppliers ||
    isLoadingExpired ||
    //isLoadingExpiring ||
    isLoadingTurnover
  ) {
    return <Spinner />;
  }

  // if (errorSuppliers || errorExpiring || errorExpired || isErrorTurnover) {
  //   return <AlertMessage message="Failed to fetch data. Please try again." />;
  // }

  return (
    <Box m="20px">
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Header title="REPORT" />
          {errorMessage && <AlertMessage message={errorMessage} />}
        </Grid>
      </Grid>

      {/* Date Picker FORM */}
      <Grid container spacing={2} justifyContent="center">
        {/* <Grid item xs={12} sm={12} md={12} lg={6}>
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            justifyContent="left">
            <Grid item xs={6} md={3} lg={3}>
              <Dropdown
                selectedCategory={selectedSupplier}
                onSelect={handleChangeSupplier}
                data={suppliers}
                title="Product Supplier"
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <DateSelection
                onChangeDate={(date) => {
                  setStartDate(date);
                }}
                dateSelected={startDate}
                selectedDateRange={"customRange"}
                lastNextDay={"Last"}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <DateSelection
                onChangeDate={(date) => {
                  setEndDate(date);
                }}
                dateSelected={endDate}
                selectedDateRange={"customRange"}
                lastNextDay={"Last"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          sx={{ textAlign: "right" }}
          // mt={2}
        >
          <Button
            onClick={downloadAllReports}
            variant="contained"
            color="success"
            startIcon={<DownloadIcon />}>
            DOWNLOAD REPORT
          </Button>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <CustomizedTables
            table_id="table1"
            data={turnoverData}
            tableName="Inventory Turnover Report"
            columnNames={turnoverColumns}
          /> */}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid
            container
            spacing={5}
            direction="row"
            // alignItems="center"
            justifyContent="center"
          >
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomizedTables
                table_id="table2"
                data={expiringData}
                tableName="Expiring Inventory"
                columnNames={expiringColumns}
              />
            </Grid> */}

            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ height: "500" }}>
              {/* <CustomizedTables
                table_id="table2"
                data={expiringData}
                tableName="Expiring Inventory"
                columnNames={expiringColumns}
              /> */}
              <CustomizedTables
                table_id="table1"
                data={turnoverData}
                tableName="Inventory Turnover Report"
                columnNames={turnoverColumns}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomizedTablesAPI
                table_id="table3"
                data={expiredData}
                tableName="Expired Product/Waste Inventory Table"
                columnNames={expiredColumns}
                totalPages={totalPages}
                dataFieldName="wasteData"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Report;
