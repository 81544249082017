import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import Header from "../components/Header";
import AlertMessage from "../components/AlertMessage";
import Spinner from "../components/Spinner";
import CustomizedTables from "../components/CustomizedTables";
import Dropdown from "../components/filter/Dropdown";
import { useErrorHandler, errorMessage } from "../hooks/useErrorHandler";
import { getStockInventory } from "../api/products";

const Inventory = () => {
  // const [suppliers, setSuppliers] = useState([]);
  // const [selectedSupplier, setSelectedSupplier] = useState("");
  const { errorMessage, handleError } = useErrorHandler();
  const columns = [
    { id: 1, headerName: "Product ID", name: "product_id" },
    { id: 2, headerName: "Product Name", name: "product_item_name" },
    { id: 3, headerName: "Quantity in Stock", name: "quantity" },
    { id: 5, headerName: "Supplier ID", name: "supplier_id" },
    { id: 6, headerName: "Supplier Name", name: "supplier" },
    {
      id: 7,
      headerName: "Supplier Shelf Life",
      name: "product_remaining_shelf_life_from_supplier",
    },
    {
      id: 8,
      headerName: "Shelf Life to Customer",
      name: "product_max_time_in_stock_before_waste",
    },
  ];

  const {
    data: inventoryData,
    error: errorStockInventory,
    isLoading: isLoadingInventoryData,
  } = useQuery(["stockInventory"], () => getStockInventory(), {
    onError: (error) => {
      handleError(error);
    },
  });

  if (isLoadingInventoryData) {
    return <Spinner />;
  }
  /*
  if (errorStockInventory) {
    return <AlertMessage message="Failed to fetch data. Please try again." />;
  }
*/
  return (
    <Box m="20px">
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Header title="INVENTORY" />
          {errorMessage && <AlertMessage message={errorMessage} />}
        </Grid>
        {isLoadingInventoryData ? (
          <Spinner />
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ height: "500" }}>
            {inventoryData && (
              <CustomizedTables
                data={inventoryData}
                tableName="Inventory"
                columnNames={columns}
                withFilter={false}
                withSearch={false}
              />
            )}
          </Grid>
        )}
      </Grid>
      {/* <Grid container spacing={2} justifyContent="center" mt={0.1}></Grid> */}
    </Box>
  );
};

export default Inventory;
