import React, { useState } from "react";
import { Grid } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const SelectSimple = ({
  name,
  selectedValue,
  handleChangeValue,
  values,
  styles,
}) => {
  const [value, setNewValue] = useState(selectedValue);
  // console.log(name)
  // console.log(selectedValue)
  // console.log(values)
  // console.log(styles)

  function handleChange(event) {
    //console.log(event.target)
    let value = event.target.value;
    setNewValue(value);
    handleChangeValue(value);
  }
  return (
    <Grid item>
      <FormControl variant="standard" sx={{ minWidth: 120 }}>
        <InputLabel id="input-lable-select-key">{name}</InputLabel>
        <Select
          labelId="select-key-label"
          value={value}
          label="Key"
          style={styles}
          onChange={handleChange}
        >
          {values.map((item) => (
            <MenuItem key={item.id} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};
